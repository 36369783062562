// 规划自动驾驶路线
import bus from '@/utils/bus';
import env from '../../../../../env.js';
import request from '@/utils/requestv2';
import Transform from '@/utils/transform';
import { uniqueByContent, createHeading } from '@/utils/index';
const API_AD_SERVER = `${env.API_AD_SERVER}`;
const highMapPathPlanUrl = `${env.API_MapPathPlan}`;
const highMapRoadPathPlanUrl = `${env.API_MapRoadPathPlan}`;
const highMapRoadPathPlanUrl2 = `${env.API_MapRoadPathPlanV2}`;
const highMapRoadPathPlanUrl3 = `${env.API_MapRoadPathPlanV3}`;

const transform = new Transform();
class AutopilotRouteSocket {
  constructor() {
    this.park = {}; // 当前网格的信息
    this.pilotSubWayRoutePaths = null;
    this.routeType = null;
    this.socket = null;
    this.timer = null;
    this.isCloseSocket = true;
    this.receiveNum = 0;
    this.sendNum = 0;
    this.receiveSuccess = false;
    this.isLoading = false;
    this.errmsg = '';
    this.routeId = null;
    this.isFirst = false; // 记录是否是第一个开始请求的，第一个请求需要链接socket，时间比较长
  }
  // 创建websocket连接 pilotSubWayRoutePaths: 站点，routeType: 0: 单程闭环，1: 去程 2:返程
  sendMessage = async ({ pilotSubWayRoutePaths, routeType, routeId, isFirst, park }) => {
    if (pilotSubWayRoutePaths) {
      this.pilotSubWayRoutePaths = pilotSubWayRoutePaths;
      this.routeType = routeType;
      this.routeId = routeId;
      this.isFirst = isFirst;
      this.park = park;
    }
    // 如果socket是断开状态,先连接,连接成功发送
    if (
      !this.socket ||
      !this.isCloseSocket ||
      (this.socket && this.socket.readyState !== WebSocket.OPEN)
    ) {
      this.createWss();
      return;
    }
    const { lng, lat, originalCoordinates } = this.pilotSubWayRoutePaths[0];
    let lngVal = lng;
    let latVal = lat;
    if (originalCoordinates) {
      let arr = originalCoordinates.split(',');
      lngVal = arr[0];
      latVal = arr[1];
    }
    const res = await this.getHightMapInfo({ lng: lngVal, lat: latVal });
    if (!res || res?.length === 0) {
      this.errmsg = '路径生成失败，未获取到高精地图瓦片';
      bus.emit('onBuildRouteRes', {
        isSucceed: false, // 是否成功
        errmsg: this.errmsg,
      });
      return;
    }
    let routPathArray = [...this.pilotSubWayRoutePaths];
    if (this.routeType === 0) {
      routPathArray.push(this.pilotSubWayRoutePaths[0]);
    }
    this.receiveNum = 0;
    this.sendNum = res.length;
    this.receiveSuccess = false;
    for (let i = 0; i < this.sendNum; i++) {
      if (this.receiveSuccess) break; // 成功了就不用再次发送了
      this.sendBuildRouteData(res[i], routPathArray);
    }
    const isShowTip = routPathArray.length > 6;
    this.isLoading = true;
    this.timer = setTimeout(
      () => {
        if (this.isLoading && !this.receiveSuccess) {
          this.errmsg = '路径生成超时，请检查网络后重试';
          this.resetSocketRequest();
        }
      },
      isShowTip || this.isFirst ? 1000 * 30 : 1000 * 15
    );
    // 设置超时成功
  };
  async getHightMapInfo(option) {
    const highHdmapInfoUrl = `${API_AD_SERVER}/ndp/aiview/out/hdmap/info`;
    let res = await request.post(
      highHdmapInfoUrl,
      {
        x: option.lng,
        y: option.lat,
        type: 'vehicleLaneBoundary',
      },
      { customHandle: true }
    );
    return res.data;
  }
  sendBuildRouteData = (mapInfo, routPathArray) => {
    try {
      const { routePreference, advanceGray } = this.park;
      const renderRoute = routPathArray.map((item, index) => {
        if (routPathArray.length !== index + 1) {
          return {
            start: {
              x: item['pos'][0],
              y: item['pos'][1],
              z: 0,
              heading: createHeading(item.heading),
            },
            end: {
              x: routPathArray[index + 1]['pos'][0],
              y: routPathArray[index + 1]['pos'][1],
              z: 0,
              heading: createHeading(routPathArray[index + 1]['heading']),
            },
            wayPoint: item?.routePath?.map((item) => {
              return {
                x: item.x,
                y: item.y,
                z: 0,
              };
            }),
            version: mapInfo.mapVersion,
            name: mapInfo.mapName,
            parkId: mapInfo.parkId,
            routePreference: advanceGray ? routePreference : undefined,
            startStationId: item.stationId,
            endStationId: routPathArray[index + 1]['stationId'],
            routeId: this.routeId,
          };
        } else {
          return {};
        }
      });
      renderRoute.pop();
      const tempStr = JSON.stringify({
        carId: 'lal',
        carName: 'web_car',
        command: 1,
        data: renderRoute,
        timestamp: Date.now(),
      });
      // 向服务器发送消息：成功
      this.socket.send(tempStr);
    } catch (error) {
      this.errmsg = '选择的停靠点坐标有问题，请检查';
      this.resetSocketRequest();
    }
  };
  createWss = () => {
    const { gray, advanceGray, advanceGrayV3 } = this.park;
    let url = highMapPathPlanUrl;
    if (gray) {
      if (advanceGrayV3) {
        url = highMapRoadPathPlanUrl3;
      } else if (advanceGray) {
        url = highMapRoadPathPlanUrl2;
      } else {
        url = highMapRoadPathPlanUrl;
      }
    }
    this.socket = new WebSocket(url);
    // WebSocket连接已经建立
    this.socket.onopen = () => {
      this.sendMessage({});
    };
    this.socket.onmessage = async (event) => {
      this.receiveNum++;
      let messageData = JSON.parse(event.data);
      if (messageData.errcode === -1) {
        if (messageData.errmsg === '请求参数[name]不允许为空!') {
          this.errmsg = '路径生成失败，未获取到高精地图瓦片';
          this.resetSocketRequest();
        } else if (!this.receiveSuccess && this.receiveNum === this.sendNum) {
          // 所有的map请求都接受,并且都失败的时候给予提示
          this.errmsg = messageData.errmsg || '规划失败,重新规划';
          this.resetSocketRequest();
        }
        return false;
      }
      this.getADRouteData(messageData);
    };
    // WebSocket连接发生错误
    this.socket.onerror = function (event) {
      this.isCloseSocket = true;
      this.isLoading = false;
    };
    // WebSocket连接已经关闭
    this.socket.onclose = function (event) {
      this.isCloseSocket = true;
      this.isLoading = false;
    };
  };
  // 请求成功或者参数失败的时候初始化Socket的请求,isEmit当接受消息成功，重置的时候不需要发送订阅，等成功的时候发送；routeId用于匹配返回的数据和请求的是否一致
  resetSocketRequest = (isEmit = true, routeId = null) => {
    this.isLoading = false;
    clearTimeout(this.timer);
    this.receiveNum = 0;
    this.sendNum = 0;
    isEmit &&
      bus.emit('onBuildRouteRes', {
        isSucceed: false, // 是否成功
        errmsg: this.errmsg,
        routeId,
      });
  };
  // 获取AD返回的成功数据
  getADRouteData = (messageData) => {
    if (this.receiveSuccess) return;
    if (messageData.errcode !== 0) {
      // 所有的map请求都接受,并且都失败的时候给予提示
      this.errmsg = '规划失败,重新规划';
      this.resetSocketRequest(true);
      return;
    }
    // 如果routePath有一段为空,说明没规划出来,则归为失败
    if (messageData.data.every((item) => item.routePath.length > 0 && item.code === 0)) {
      let tempRoutePath = [];
      let motorPath = [];
      let zone;
      let tempPilotMapRouteDTO = [];
      const { advanceGray } = this.park;
      this.resetSocketRequest(false);
      this.receiveSuccess = true;
      let curRouteId; // 当前规划的线路ID
      for (const item of messageData.data) {
        const {
          command,
          routePath,
          keyPoints,
          priorityPoints,
          noDrivePoints,
          version,
          timestamp,
          routePreference,
          startStationId,
          endStationId,
          routeId,
        } = item;
        zone = item.zone;
        curRouteId = routeId;
        routePath.map((path) => {
          // 机动车道放到一个数组当中
          if (this.park.gray && path.lane_type === 1) {
            // 灰度网格
            motorPath.push(transform.utmToLonlat(path.path_points, zone));
          }
          if (!this.park.gray && this.isMotorway(path.lane_type)) {
            motorPath.push(transform.utmToLonlat(path.path_points, zone));
          }
          tempRoutePath = tempRoutePath.concat(path.path_points);
        });
        tempPilotMapRouteDTO.push({
          command,
          keyPoints: uniqueByContent(keyPoints, (item) => item.x),
          priorityPoints,
          noDrivePoints,
          version,
          timestamp,
          routePreference: advanceGray ? routePreference : undefined,
          startStationId,
          endStationId,
        });
      }
      bus.emit('onBuildRouteRes', {
        isSucceed: true,
        ADRoutePath: tempRoutePath.length > 0 ? transform.utmToLonlat(tempRoutePath, zone) : [],
        motorPath,
        pilotMapRouteDTO: tempPilotMapRouteDTO,
        zone,
        routeId: curRouteId,
      });
    } else if (this.receiveNum === this.sendNum) {
      let curRouteId = null;
      if (messageData.data.some((item) => item.routePath.length === 0)) {
        curRouteId = messageData.data[0]['routeId'];
      }
      // 所有的map请求都接受,并且都失败的时候给予提示
      this.errmsg = '规划失败,重新规划';
      this.resetSocketRequest(true, curRouteId);
    }
  };
  isMotorway = (value) => {
    return ![5, 6].includes(Number(value));
  };
  resetAutopilotRouteSocket() {
    this.park = {};
    this.routeId = null;
    this.pilotSubWayRoutePaths = null;
    this.routeType = null;
    this.socket = null;
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timer = null;
    this.isCloseSocket = true;
    this.receiveNum = 0;
    this.sendNum = 0;
    this.receiveSuccess = false;
    this.errmsg = '';
    this.isFirst = false;
  }
}
export default AutopilotRouteSocket;
