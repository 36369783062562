import proj4 from "proj4"

const a = 6378245.0; //长半轴
const ee = 0.00669342162296594323;  //偏心率平方

class Transform {
    /**
     * @Author: zxf
     * @description: UTM转经纬度
     * @param {*} geometry
     * @param {*} zoneparams
     * @param {*} isChinaLngLat
     * @param {*} sureZone
     * @return {*}
     */    
    utmToLonlat(geometry ,zoneparams, isChinaLngLat=true, sureZone = false) {
        const zone =sureZone?zoneparams || window.zone: window.zone || zoneparams; 
        const geos = []
        proj4.defs(`EPSG:326${zone}`, `+proj=utm +zone=${zone} +datum=WGS84 +units=m +no_defs`);
        for(let i = 0;i < geometry.length; i++) {
            const ele = geometry[i];
            let _geo = proj4(`EPSG:326${zone}`, "EPSG:4326", [ele.x, ele.y]);
            let geo = isChinaLngLat ? this.wgs84_to_gcj02(_geo[0], _geo[1]):[_geo[0], _geo[1]];
            // if(ele.hasOwnProperty('z')) {
            //     geo[2] = 0;
            // }
            geos.push(geo)
        }
        return geos;
    }

    /**
     * @description: GCJ02(火星坐标系)转WGS84
     * @param {*} lng 火星坐标系的经度
     * @param {*} lat 火星坐标系纬度
     * @return {*}
     * @author: zhangqi
     */
    wgs84_to_gcj02(lng, lat) {
        if (this.out_of_china(lng, lat)) {
            return lng, lat;
        }
        let dlat = this._transformlat(lng - 105.0, lat - 35.0);
        let dlng = this._transformlng(lng - 105.0, lat - 35.0);
        let radlat = lat / 180.0 * Math.PI;
        let magic = Math.sin(radlat);
        magic = 1 - ee * magic * magic;
        let sqrtmagic = Math.sqrt(magic);
        dlat = (dlat * 180.0) / ((a * (1 - ee)) / (magic * sqrtmagic) * Math.PI);
        dlng = (dlng * 180.0) / (a / sqrtmagic * Math.cos(radlat) * Math.PI);
        let mglat = lat + dlat;
        let mglng = lng + dlng;
        return [mglng, mglat];
    }

    _transformlat(lng, lat) {
        let ret = -100.0 + 2.0 * lng + 3.0 * lat + 0.2 * lat * lat + 0.1 * lng * lat + 0.2 * Math.sqrt(Math.abs(lng));
        ret += (20.0 * Math.sin(6.0 * lng * Math.PI) + 20.0 * Math.sin(2.0 * lng * Math.PI)) * 2.0 / 3.0;
        ret += (20.0 * Math.sin(lat * Math.PI) + 40.0 * Math.sin(lat / 3.0 * Math.PI)) * 2.0 / 3.0;
        ret += (160.0 * Math.sin(lat / 12.0 * Math.PI) + 320 * Math.sin(lat * Math.PI / 30.0)) * 2.0 / 3.0;
        return ret;
    }

    _transformlng(lng, lat) {
        let ret = 300.0 + lng + 2.0 * lat + 0.1 * lng * lng + 0.1 * lng * lat + 0.1 * Math.sqrt(Math.abs(lng));
        ret += (20.0 * Math.sin(6.0 * lng * Math.PI) + 20.0 * Math.sin(2.0 * lng * Math.PI)) * 2.0 / 3.0;
        ret += (20.0 * Math.sin(lng * Math.PI) + 40.0 * Math.sin(lng / 3.0 * Math.PI)) * 2.0 / 3.0;
        ret += (150.0 * Math.sin(lng / 12.0 * Math.PI) + 300.0 * Math.sin(lng / 30.0 * Math.PI)) * 2.0 / 3.0;
        return ret;
    }

    /**
     * @Author: zxf
     * @param {*} lngLat
     * @param {*} zoneparams
     * @param {*} isChinaLngLat
     * @return {*}
     */    
    lngLatToUtm(lngLat, zoneparams, isChinaLngLat=true) {
        const zone = window.zone || zoneparams; 
        proj4.defs(`EPSG:326${zone}`, `+proj=utm +zone=${zone} +datum=WGS84 +units=m +no_defs`);
        const _utms = lngLat.map(item => {
            let data =isChinaLngLat ? this.gcj02_to_wgs84(item.lng, item.lat):[item.lng, item.lat];
            return proj4( "EPSG:4326", `EPSG:326${zone}`, [ ...data, 0])
        });
        return _utms;
    }

    /**
     * @description: GCJ02(火星坐标系)转WGS84
     * @param {*} lng 火星坐标系的经度
     * @param {*} lat 火星坐标系纬度
     * @return {*}
     * @author: zhangqi
     */
    gcj02_to_wgs84(lng, lat) {
        if (this.out_of_china(lng, lat)) {
            return [lng, lat];
        }
        let initDelta = 0.01;
	    let threshold = 0.0000001;
	    let dLat = initDelta;
	    let dLng = initDelta;
	    let mLat = lat - dLat;
	    let mLng = lng - dLng;
	    let pLat = lat + dLat;
	    let pLon = lng + dLng;
	    let wgsLat = 0;
	    let wgsLng = 0;
	    let i = 0;
	    while (true) {
	        wgsLat = (mLat + pLat) / 2;
	        wgsLng = (mLng + pLon) / 2;
	        let tmp = this.wgs84_to_gcj02(wgsLng,wgsLat);
	        dLat = tmp[1] - lat;
	        dLng = tmp[0] - lng;
	        if ((Math.abs(dLat) < threshold) && (Math.abs(dLng) < threshold)) {
	            break;
	        }
	        if (dLat > 0) { pLat = wgsLat; } else { mLat = wgsLat;}
	        if (dLng > 0) { pLon = wgsLng; } else { mLng = wgsLng;}

	        if (++i > 1000) break;
	    }
        return [wgsLng, wgsLat]
    }

    /**
     * @description: 判断是否在国内，不在国内不做偏移
     * @param {*} lng
     * @param {*} lat
     * @return {*}
     * @author: zhangqi
     */    
    out_of_china(lng, lat) {
        return !(73.66 < lng < 135.05 && 3.86 < lat < 53.55);
    }
}

export default Transform;
