<template>
  <div class="calibration">
    <div id="headerBox" ref="headerRef" class="header">
      <div :style="{ height: `${appBarHeight}px`, backgroundColor: 'white' }"></div>
      <div class="navBar">
        <img
          class="back"
          @click="goback()"
          src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/back.png"
          alt=""
        />
        <div class="title">停靠点校准</div>
      </div>
    </div>
    <div
      class="header-placehold"
      :style="{
        height: `${headerH + appBarHeight}px`,
        backgroundColor: 'white',
      }"
    ></div>
    <div class="content">
      <div class="status" :data-bg="status">
        <div class="status-icon" :data-icon="status"></div>
      </div>
      <div class="status-tip">{{ statusTip }}</div>
      <div class="status-res" :data-res="status">{{ statusRes }}</div>
      <div class="status-des">{{ statusDes }}</div>
      <div class="route_list" v-if="!isBatchBuilding && routeList.length > 0">
        <div class="route" v-for="(item, idx) in routeList" :key="'route_' + idx">
          <div class="route-info">
            <div class="station-info">
              <div class="left-icon">
                <div class="circle-station"></div>
                <div class="circle-gray" v-for="i in 7" :key="'gray_' + i"></div>
                <div class="circle-station m_t"></div>
              </div>
              <div class="station">
                <div class="station-name">{{ item.pilotSubWayRoutePaths[0].stationName }}</div>
                <div class="station-name">
                  {{
                    item.pilotSubWayRoutePaths[item.pilotSubWayRoutePaths.length - 1].stationName
                  }}
                </div>
              </div>
            </div>
            <div class="route-build-statue" :data-build-status="item.buildStatus"></div>
          </div>
          <div v-if="item.buildStatus === 2" class="route-msg">
            {{ item.buildErrorMsg }}
          </div>
        </div>
      </div>
      <div v-show="status !== 1" class="bottom">
        <div class="btn" @click="lastPage()">
          {{ status === 2 ? '完成' : '我知道了' }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader';
import { param2Obj } from '@/utils';
import NDialog from '@/components/NDialog';
import AutopilotRouteSocket from '../components/common/BuildRoute';
import bus from '@/utils/bus';
import { getRouteById } from '@/api/api';
import { getGryByParkCode, batchSaveRoute, getPoiCalibrationCache } from '@/api/apiv2';
let entryType; // 标记从哪进入，1是从线路规划，另一个入口是原生的校准
let instantiationBuildRoute;
let routeIdList = [];
let curBuildingRoute = {}; // 当前正在生成的路线信息
let buildingIndex = 0;
let stationId = null;
let lnglatArr = []; //当前点更新后的02坐标
let pos = []; //当前点更新后的utm坐标
export default {
  components: {},
  data() {
    return {
      headerH: 48,
      park: {
        gray: true,
        advanceGrayV3: false,
        advanceGray: true,
        routePreference: ['0'],
      },
      status: 1, // 1 是正在生成 2是校准成功 3是校准失败
      isBatchBuilding: false, // 是否正在
      buildingStatus: 1, // 路线生成的结果
      routeList: [],
    };
  },
  computed: {
    appBarHeight() {
      let val = Number(sessionStorage.getItem('_appBarHeight'));
      if (val) {
        return Number(sessionStorage.getItem('_appBarHeight')) - 22;
      }
      return 0;
    },
    statusTip() {
      if (this.status === 1) {
        return '提示';
      } else if (this.status === 2) {
        return '校准成功';
      } else if (this.status === 3) {
        return '校准失败';
      } else if (this.status === 4) {
        return '保存失败';
      }
    },
    statusRes() {
      if (this.status === 1) {
        return '正在重新生成线路';
      } else if (this.status === 2) {
        return '本次校准结果已保存';
      } else if (this.status === 3) {
        return '本次校准结果不会保存';
      } else if (this.status === 4) {
        return '本次保存不成功';
      }
    },
    statusDes() {
      if (this.status === 1) {
        return '若中途退出本页，本次校准结果将不会保存';
      } else if (this.status === 2) {
        return '以下关联线路已更新';
      } else if (this.status === 3) {
        return '以下关联线路更新失败';
      }
    },
  },
  methods: {
    // 返回
    async goback() {
      if (this.status === 1) {
        const result = await NDialog.confirm({
          title: '提示',
          message: '正在重新生成线路，退出后本次校准结果将不会保存，是否退出？',
          okText: '退出',
          cancelText: '暂不退出',
        });
        if (result === 'confirm') {
          this.lastPage();
        }
      } else {
        this.lastPage();
      }
    },
    lastPage() {
      if (entryType === 1) {
        this.$router.back();
      } else {
        window.location.href = 'neolix://more';
      }
    },
    async getPoiCalibrationCacheServer(parkCode) {
      const res = await getPoiCalibrationCache({ id: stationId });
      lnglatArr = [res.lng, res.lat];
      pos = res.pos;
      routeIdList = res.routeIds;
      this.getGryByParkCodeServer(parkCode);
    },
    async getGryByParkCodeServer(parkCode) {
      const res = await getGryByParkCode(parkCode);
      this.park = res;
      instantiationBuildRoute = new AutopilotRouteSocket();
      this.buildingStatus = 2;
      this.isBatchBuilding = true;
      this.startBuildRoute(true);
      bus.on('onBuildRouteRes', this.handleBuildRouteRes);
    },
    // 开始批量生成路线
    async startBuildRoute(isFirst = false) {
      let routeId = routeIdList[buildingIndex];
      curBuildingRoute = await getRouteById({ routeId });
      curBuildingRoute.name = curBuildingRoute.subwayName;
      curBuildingRoute.id = routeId;
      curBuildingRoute.buildStatus = 1; // 1 正在生成线路，1是成功，2是失败
      const idx = curBuildingRoute.pilotSubWayRoutePaths.findIndex(
        (station) => station.stationId === stationId
      );
      // 以下字段接口返回
      // curBuildingRoute.pilotSubWayRoutePaths[idx].originalCoordinates = '120.203165,33.347138';
      // curBuildingRoute.pilotSubWayRoutePaths[idx].pos = [239272.06957464584, 3693444.1887308517];
      // curBuildingRoute.pilotSubWayRoutePaths[idx].lng = 120.203165;
      // curBuildingRoute.pilotSubWayRoutePaths[idx].lat = 33.347138;

      curBuildingRoute.pilotSubWayRoutePaths[
        idx
      ].originalCoordinates = `${lnglatArr[0]},${lnglatArr[1]}`;
      curBuildingRoute.pilotSubWayRoutePaths[idx].pos = pos;
      curBuildingRoute.pilotSubWayRoutePaths[idx].lng = lnglatArr[0];
      curBuildingRoute.pilotSubWayRoutePaths[idx].lat = lnglatArr[1];
      console.log(curBuildingRoute.pilotSubWayRoutePaths);
      this.$set(this.routeList, buildingIndex, curBuildingRoute);
      const { pilotSubWayRoutePaths, routeType } = curBuildingRoute;
      instantiationBuildRoute.sendMessage({
        park: this.park,
        pilotSubWayRoutePaths,
        routeType,
        routeId,
        isFirst,
      });
    },
    // 监听调用Ad路线生成的返回结果
    async handleBuildRouteRes({
      isSucceed,
      ADRoutePath,
      motorPath,
      pilotMapRouteDTO,
      errmsg,
      routeId,
    }) {
      /**
       * 异步返回的数据处理
       * 1，只有当前buildStatus状态是-1的才能继续执行，否则就是异步回调，数据不匹配
       * 2，通过透传返回的routeId校验是不是当前请求的，多点routing服务不考虑
       */

      if (
        this.routeList[buildingIndex]['buildStatus'] !== 1 ||
        (routeId && curBuildingRoute.routeId !== routeId)
      )
        return;

      if (isSucceed) {
        curBuildingRoute.buildStatus = 1;
        const centerIndex = parseInt(ADRoutePath.length / 2);
        const routeCenter = ADRoutePath[centerIndex];
        curBuildingRoute.lng = routeCenter[0];
        curBuildingRoute.lat = routeCenter[1];
        curBuildingRoute.motorwayDistance = this.computerMotorwayDistance(motorPath);
        curBuildingRoute.routeDistance = Math.round(AMap.GeometryUtil.distanceOfLine(ADRoutePath));
        // 此处保存路线，这个地方在联调的时候注意，需要对应的字段应该是 path
        curBuildingRoute.vehicleLaneLine = JSON.stringify([motorPath]);
        curBuildingRoute.pilotMapRouteDTO = this.park.gray ? pilotMapRouteDTO : null;
        curBuildingRoute.pilotSubWayCoordinates = ADRoutePath;
      } else {
        curBuildingRoute.buildStatus = 2;
        curBuildingRoute.buildErrorMsg = ~errmsg.indexOf('超时')
          ? '原因：生成超时'
          : '原因：生成失败';
        this.buildingStatus = 3;
      }
      this.$set(this.routeList, buildingIndex, curBuildingRoute);
      // 有一个生成失败，则整个都失败
      if (buildingIndex === routeIdList.length - 1) {
        if (this.buildingStatus === 2) {
          // 生成成功，调批量保存接口
          this.batchSaveRouteServer();
        } else if (this.buildingStatus === 3) {
          // 生成失败
          this.status = 3;
          this.isBatchBuilding = false;
        }
      } else {
        buildingIndex++;
        this.startBuildRoute();
      }
    },
    // 批量保存接口
    async batchSaveRouteServer() {
      const res = await batchSaveRoute({ routeList: this.routeList, stationId });
      this.isBatchBuilding = false;
      if (res.code === '10000') {
        this.status = 2;
        bus.emit('onCalibrationSuccess', {
          x: lnglatArr[0],
          y: lnglatArr[1],
        });
      } else {
        this.$toast('路线保存失败');
        this.status = 3;
      }
    },
    computerMotorwayDistance(motorPath) {
      if (motorPath.length === 0) return 0;
      let len = 0;
      motorPath.forEach((element) => {
        len += AMap.GeometryUtil.distanceOfLine(element);
      });
      return Math.round(len);
    },
  },
  created() {
    entryType = 0;
    routeIdList = [];
    curBuildingRoute = {}; // 当前正在生成的路线信息
    buildingIndex = 0;
    stationId = null;
    lnglatArr = [];
    pos = [];
    instantiationBuildRoute = null;
  },
  mounted() {
    buildingIndex = 0;
    const query = param2Obj(location.href);
    if (query.parkCode && query.stationId) {
      const { parkCode, stationId: poiId, entryType: entry } = query;
      stationId = Number(poiId);
      entryType = Number(entry);
      this.getPoiCalibrationCacheServer(parkCode);
    } else {
      this.$toast('数据异常');
    }
    AMapLoader.load({
      key: 'e618efe9bc3dbbbd1ddfa9d847f49d74',
      version: '2.0',
      plugins: ['AMap.Geolocation', 'AMap.Geocoder'],
    })
      .then((AMap) => {})
      .catch((error) => console.log(error));
  },
  beforeDestroy() {
    this.lastPage();
    bus.off('onBuildRouteRes', this.handleBuildRouteRes);
  },
};
</script>
<style scoped lang="scss">
.calibration {
  width: 100%;
  height: 100%;
  font-size: initial;
  background: #fff;
  .header {
    width: 100vw;
    position: fixed;
    z-index: 1;
    background: white;
    top: 0;
    .navBar {
      height: 48px;
      line-height: 48px;
      position: relative;
      font-size: 16px;
      color: #000000;
      letter-spacing: 0;
      text-align: center;
      font-weight: bold;

      .back {
        width: 0.64rem;
        height: 0.64rem;
        position: absolute;
        top: 0.07rem;
        left: 0.2rem;
        z-index: 3;
      }
      .title {
        font-size: 15px;
        font-weight: 600;
      }
    }
  }
  .header-placehold {
    width: 100%;
    height: 48px;
    background: rgba(245, 246, 250, 1);
  }
  .m_t {
    margin-top: 2px;
  }
  .content {
    position: relative;
    background: #fff;
    padding-bottom: 90px;
    .status {
      width: 96px;
      height: 96px;
      margin: auto;
      margin-top: 80px;
      display: flex;
      align-items: center;
      justify-content: center;
      &[data-bg='1'] {
        background: url('../../../assets/LogisticsMap/calibration/building-bg.png') no-repeat center;
        background-size: 96px;
      }
      &[data-bg='2'] {
        background: url('../../../assets/LogisticsMap/calibration/success-bg.png') no-repeat center;
        background-size: 96px;
      }
      &[data-bg='3'],
      &[data-bg='4'] {
        background: url('../../../assets/LogisticsMap/calibration/fail-bg.png') no-repeat center;
        background-size: 96px;
      }
      .status-icon {
        &[data-icon='1'] {
          width: 64px;
          height: 64px;
          background: url('../../../assets/LogisticsMap/calibration/building.png') no-repeat center;
          background-size: 64px;
          animation: rotate 0.9s infinite linear;
        }
        &[data-icon='2'] {
          width: 46px;
          height: 32px;
          background: url('../../../assets/LogisticsMap/calibration/success.png') no-repeat center;
          background-size: 46px 32px;
        }
        &[data-icon='3'],
        &[data-icon='4'] {
          width: 39px;
          height: 39px;
          background: url('../../../assets/LogisticsMap/calibration/fail.png') no-repeat center;
          background-size: 39px;
        }
        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    }
    .status-tip {
      margin: 38px 0 30px;
      font-size: 18px;
      font-weight: 600;
      color: rgba(0, 0, 0, 1);
      text-align: center;
    }
    .status-res {
      font-size: 21px;
      font-weight: 400;
      text-align: center;
      &[data-res='1'] {
        color: rgba(70, 95, 253, 1);
      }
      &[data-res='2'] {
        color: rgba(38, 197, 117, 1);
      }
      &[data-res='3'],
      &[data-res='4'] {
        color: rgba(255, 45, 85, 1);
      }
    }
    .status-des {
      font-size: 14px;
      font-weight: 400;
      color: rgba(151, 152, 171, 1);
      text-align: center;
      margin-top: 12px;
      margin-bottom: 28px;
    }
    .route_list {
      padding: 0 20px;
      box-sizing: border-box;
      .route {
        border-radius: 6px;
        background: rgba(244, 247, 249, 1);
        padding: 10px 11px 10px 15px;
        & + .route {
          margin-top: 12px;
        }
        .route-info {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .station-info {
            display: flex;
            align-items: center;
            .left-icon {
              box-sizing: border-box;
              margin-right: 11px;
              .circle-station {
                position: relative;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background: rgba(70, 95, 253, 1);
                &::before {
                  content: '';
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  width: 3px;
                  height: 3px;
                  border-radius: 50%;
                  background: white;
                }
              }
              .circle-gray {
                width: 1px;
                height: 1px;
                background: rgba(193, 193, 205, 1);
                border-radius: 50%;
                margin-top: 2px;
                margin-left: 3px;
              }
            }
            .station {
              .station-name {
                font-size: 14px;
                font-weight: 400;
                color: rgba(0, 0, 0, 1);
                & + .station-name {
                  margin-top: 10px;
                }
              }
            }
          }
          .route-build-statue {
            width: 28px;
            height: 28px;
            &[data-build-status='1'] {
              background: url('../../../assets/LogisticsMap/calibration/success-green.png')
                no-repeat center;
              background-size: 28px;
            }
            &[data-build-status='2'] {
              background: url('../../../assets/LogisticsMap/calibration/fail-red.png') no-repeat
                center;
              background-size: 28px;
            }
          }
        }
      }
      .route-msg {
        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 45, 85, 1);
        margin-top: 8px;
      }
    }
    .bottom {
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      height: 86px;
      padding: 16px 24px 24px;
      box-sizing: border-box;
      background: white;
      .btn {
        width: 100%;
        height: 46px;
        border-radius: 23px;
        background: rgba(70, 95, 253, 1);
        font-size: 14px;
        font-weight: 600;
        color: rgba(255, 255, 255, 1);
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
</style>
